import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";

export default function App() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [kodeLink, setKodeLink] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKodeLink("");
    setError("");
    setDate("");
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://netflixotp.rfstore.net/email-otp",
        {
          email: email,
        }
      );
      setKodeLink(response.data.link);
      setDate(response.data.date);
    } catch (error) {
      setError(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy, HH.mm");
  }

  return (
    <main className="p-10">
      <div className="flex flex-col gap-5">
        <h1 className="text-3xl font-bold">
          Request Kode/Access Untuk Menonton Netflix
        </h1>
        <p className="text-lg">
          Ini adalah website untuk request kode/access untuk menonton netflix
          khusus untuk customer RF Store.
        </p>
        <div className="mt-8 flex flex-col gap-7 lg:px-[13rem]">
          <div className="flex flex-col gap-5 w-full">
            <label htmlFor="email" className="font-bold text-lg">
              Masukan Email Netflix Anda
            </label>
            <input
              className="p-3 border-2 border-grey-500 rounded-lg max-w-[60rem]"
              id="email"
              type="email"
              placeholder="Masukan Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubmit}
              className="bg-black p-2 rounded-xl text-xl text-red-500 font-bold w-[8rem] hover:bg-red-500 hover:text-white"
            >
              Submit
            </button>
            {isLoading && <div>Silahkan tunggu sebentar...</div>}
          </div>

          {kodeLink && (
            <div className="border-2 border-grey-500 min-h-[15rem] p-12">
              <span className="flex flex-col gap-5">
                <p className="text-xl">
                  Diminta pada tanggal {formatDate(date)} WIB
                </p>
                <a
                  target="_blank"
                  href={kodeLink}
                  className="font-bold text-xl text-white bg-red-600 p-5 rounded-lg hover:bg-red-800 text-center"
                >
                  <button>Klik Untuk Dapatkan Kode / Access Menonton</button>
                </a>
                <p>
                  *Klik submit kembali jika link tidak bisa atau waktu sudah
                  kadaluarsa lebih dari 15 menit
                </p>
              </span>
            </div>
          )}
          {error && (
            <div className="border-2 border-grey-500 min-h-[10rem] p-12 bg-red-200">
              <span className="flex flex-col gap-5">
                <p className="text-2xl text-center">{error}</p>
              </span>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
